import React from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import {withNamespaces} from 'react-i18next'

import Layout from 'components/Layout'
import FeatureScrollPanel from 'components/fleerp/FeatureScrollPanel'

export const IndexPage = ({t, navigate, location}) => (
  <Layout center={false} navigate={navigate} location={location}>
    <Helmet
      title={t('fleerp.IndexPage.meta.title')}
      meta={[
        {
          name: 'description',
          content: t('fleerp.IndexPage.meta.description')
        },
        {
          name: 'keywords',
          content: t('fleerp.IndexPage.meta.keywords')
        }
      ]}
    />
    <FeatureScrollPanel />
  </Layout>
)

IndexPage.propTypes = {
  t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default withNamespaces()(IndexPage)
