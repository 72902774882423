import React from 'react'
import PropTypes from 'prop-types'

import {Row, Col} from 'react-flexbox-grid'

import classes from './Feature.module.scss'

export const Feature = React.forwardRef((props, ref) => {
  if (props.even) {
    return (
      <div ref={ref}>
        <Row middle='xs' center='xs'>
          <Col md={12} lg={8} className={classes.title}>{props.title}</Col>
        </Row>

        <Row middle='md' center='md'>
          <Col md={12} lg={7} className={classes.content}>{props.content}</Col>
        </Row>

        <Row middle='md' center='md'>
          <Col md={12} lg={4}>
            <img src={props.img} className={classes.image} />
          </Col>

          <Col md={12} lg={4} lgOffset={1}>
            <ul className={classes.features}>
              {
                props.descriptions.map((d, i) => (
                  <li className={classes.feature} key={i}>
                    <strong>{d.title}</strong>
                    <p className={classes.descriptions}>{d.content}</p>
                  </li>
                ))
              }
            </ul>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div ref={ref} style={{backgroundColor: '#f0f0f0'}}>
      <Row middle='xs' center='xs'>
        <Col md={12} ld={8} className={classes.title}>{props.title}</Col>
      </Row>

      <Row middle='xs' center='xs'>
        <Col md={12} lg={7} className={classes.content}>{props.content}</Col>
      </Row>

      <Row middle='xs' center='xs'>
        <Col md={12} lg={4}>
          <ul className={classes.features}>
            {
              props.descriptions.map((d, i) => (
                <li className={classes.feature} key={i}>
                  <strong>{d.title}</strong>
                  <p className={classes.descriptions}>{d.content}</p>
                </li>
              ))
            }
          </ul>
        </Col>

        <Col md={12} lg={4} lgOffset={1}>
          <img src={props.img} className={classes.image} />
        </Col>
      </Row>
    </div>
  )
})

Feature.propTypes = {
  img: PropTypes.string.isRequired,
  even: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  descriptions: PropTypes.array.isRequired
}

export default Feature
