import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/common/Button'
import {Large} from 'components/common/Responsive'

import classes from './Button.module.scss'

export class ButtonWrapper extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired
  }

  handleClick = () => {
    this.props.onClick(this.props.name)
  }

  render () {
    const {name, children} = this.props

    return (
      <Large>
        {
          matches => (
            <Button
              name={name}
              onClick={this.handleClick}
              className={classes.button}
              style={{width: matches ? 'auto' : '100%'}}
            >
              {children}
            </Button>
          )
        }
      </Large>
    )
  }
}

export default ButtonWrapper
