import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'

export const ButtonGroup = ({items, onClick}) => (
  <div style={{margin: '15px 5px', textAlign: 'center'}}>
    {
      items.map((v, i) => (
        <Button
          key={i}
          name={v.name}
          onClick={onClick}
        >
          {v.title}
        </Button>
      ))
    }
  </div>
)

ButtonGroup.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ButtonGroup
