import React from 'react'
import PropTypes from 'prop-types'

import {Grid} from 'react-flexbox-grid'

import Feature from './Feature'
import ButtonGroup from './ButtonGroup'

import poisImage from './pois.png'
import alarmsImage from './alarms.png'
import reportsImage from './reports.png'
import ecodrivingImage from './ecodriving.png'
import fuelReportImage from './fuelReport.png'
import geofencingImage from './geofencing.png'
import realTimeTrackingImage from './realTimeTracking.png'

import {withNamespaces} from 'react-i18next'

export class FeatureScrollPanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.pois = React.createRef()
    this.alarms = React.createRef()
    this.reports = React.createRef()
    this.fuelReport = React.createRef()
    this.geofencing = React.createRef()
    this.ecodriving = React.createRef()
    this.realTimeTracking = React.createRef()
  }

  handleButtonGroupClick = name => {
    this[name].current.scrollIntoView({behavior: 'smooth'})
  }

  render () {
    const {t} = this.props

    const features = [
      {
        img: realTimeTrackingImage,
        name: 'realTimeTracking',
        title: t('fleerp.FeatureScrollPanel.realTimeTracking.title'),
        content: t('fleerp.FeatureScrollPanel.realTimeTracking.content'),
        descriptions: t('fleerp.FeatureScrollPanel.realTimeTracking.descriptions', {returnObjects: true})
      },
      {
        img: ecodrivingImage,
        name: 'ecodriving',
        title: t('fleerp.FeatureScrollPanel.ecodriving.title'),
        content: t('fleerp.FeatureScrollPanel.ecodriving.content'),
        descriptions: t('fleerp.FeatureScrollPanel.ecodriving.descriptions', {returnObjects: true})
      },
      {
        img: alarmsImage,
        name: 'alarms',
        title: t('fleerp.FeatureScrollPanel.alarms.title'),
        content: t('fleerp.FeatureScrollPanel.alarms.content'),
        descriptions: t('fleerp.FeatureScrollPanel.alarms.descriptions', {returnObjects: true})
      },
      {
        img: reportsImage,
        name: 'reports',
        title: t('fleerp.FeatureScrollPanel.reports.title'),
        content: t('fleerp.FeatureScrollPanel.reports.content'),
        descriptions: t('fleerp.FeatureScrollPanel.reports.descriptions', {returnObjects: true})
      },
      {
        img: fuelReportImage,
        name: 'fuelReport',
        title: t('fleerp.FeatureScrollPanel.fuelReport.title'),
        content: t('fleerp.FeatureScrollPanel.fuelReport.content'),
        descriptions: t('fleerp.FeatureScrollPanel.fuelReport.descriptions', {returnObjects: true})
      },
      {
        img: geofencingImage,
        name: 'geofencing',
        title: t('fleerp.FeatureScrollPanel.geofencing.title'),
        content: t('fleerp.FeatureScrollPanel.geofencing.content'),
        descriptions: t('fleerp.FeatureScrollPanel.geofencing.descriptions', {returnObjects: true})
      },
      {
        img: poisImage,
        name: 'pois',
        title: t('fleerp.FeatureScrollPanel.pois.title'),
        content: t('fleerp.FeatureScrollPanel.pois.content'),
        descriptions: t('fleerp.FeatureScrollPanel.pois.descriptions', {returnObjects: true})
      }
    ]

    return (
      <React.Fragment>
        <ButtonGroup items={features} onClick={this.handleButtonGroupClick} />
        <Grid style={{width: '100%', padding: 0, overflow: 'hidden'}}>
          {
            features.map((v, i) => (
              <Feature
                key={i}
                ref={this[v.name]}
                img={v.img}
                even={i % 2 === 0}
                title={v.title}
                content={v.content}
                descriptions={v.descriptions}
              />
            ))
          }
        </Grid>
      </React.Fragment>
    )
  }
}

export default withNamespaces()(FeatureScrollPanel)
